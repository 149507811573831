<template>
  <div class="pageMain">
    <div class="flex">
      <!-- 组织项目树 -->

      <OriginTree class="tree">
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
         
          >
          <el-form :inline="true" :model="searchForm">
            <el-form-item label="" class="formItem">
              <el-input
                v-model="searchForm.user"
                size="small"
                placeholder="项目"
              ></el-input>
            </el-form-item>
          </el-form>
          </SearchLeft>
        </template>
      </OriginTree>
      
      <!--右边列表  -->
      <div class="form">
        <SearchHead @search="search" @reset="reset" :isExport="true" @tableExport="tableExport">
          <el-form :inline="true" :model="searchForm">
            <el-form-item label="设备类型">
              <el-select
                v-model="searchForm.region"
                size="small"
                placeholder="设备类型"
              >
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </SearchHead>
        <CustomTables
          :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          height="55vh"
          :total="total"
          title="设备统计"
        >
        
          <!-- <template slot="btns">
            <el-button type="success" size="small" @click="addFrom"
            icon="el-icon-plus"  class="btn">新建</el-button
            >
          </template> -->
        
          <el-table-column type="selection" align="center" width="55">
      </el-table-column>
          <el-table-column prop="date" label="机构名称"  align="center"> </el-table-column>
          <el-table-column prop="name" label="设备类型"  align="center"> </el-table-column>
          <el-table-column prop="name" label="设备数"  align="center"> </el-table-column>
          <el-table-column prop="name" label="在线数"  align="center"> </el-table-column>
          <el-table-column prop="name" label="告警设备数"  align="center"> </el-table-column>
          <el-table-column prop="name" label="违规设备数"  align="center"> </el-table-column>
        </CustomTables>
      </div>
    </div>
  </div>
</template>

<script>
import OriginTree from "../../../components/common/OriginTree.vue";
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import SearchLeft from "../../../components/common/SearchLeft.vue";
export default {
  components: {
    OriginTree,
    SearchHead,
    CustomTables,
    SearchLeft
  },
  name: "statistics",
  data() {
    return {
      searchForm: {},
      tableData: [
  
      ],
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      total: 0,
      show:true,
    };
  },
  mounted() {},
  methods: {
    addFrom() {},
    search(){},
    reset(){},
    tableExport(){
      // 导出
    },
    handleSelectionChange(val){
      console.log(val);
      
    }
  },
};
</script>

<style scoped lang="less">
.flex {
  display: flex;
  // justify-content: space-between;
}
.tree {
  width: 20%;
}
.form {
  width: 78%;
  margin-left: 2%;
}
.formItem{
  margin-bottom: 0px;
}
.btn{
  background-color: #008f4d;
  // color: #008f4d;
}

</style>
